<template>
  <transition name="flixFadeIn">
    <span :key="updateKey">
      <h3 class="flix-html-h3">{{ $t('message.add', { name: $t('message.image') }) }}</h3>
      <div class="flix-text-info flix-form-group"><flixIcon :id="'info-sign'" /> {{ $t('message.imagePlaceholder') }}</div>
      <flixUpload  class="uploadImg" :settings="newSettings"/>
      <a v-if="data.image" href="#" class="flix-html-a flix-text-danger" @click.prevent="deleteImage()"><flixIcon :id="'bin'" /></a>
    </span>
  </transition>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      variables: this.$getUserVariables(),
      updateKey: new Date().getTime(),
      newSettings: this.getSettings()
    }
  },
  methods: {
    getSettings () {
      var set = {
        onSave: function (data) { this.saveImage(data) }.bind(this),
        label: '<span class="flix-btn flix-btn-default">' + this.$t('message.upload', { name: this.$t('message.image') }) + '</span>',
        maxWidth: 350,
        maxHeight: 350
      }
      if (this.data.image) {
        set.image = { dataUrl: this.data.image }
      }
      return set
    },
    saveImage (data) {
      var send = {
        user: this.variables.user.md5_id,
        data: {
          img: data.dataUrl,
          type: data.info.type,
          name: data.info.name
        }
      }
      this.$flix_post({
        data: send,
        url: 'uploads/set',
        callback: function (ret) {
          this.data.image = ret.data[1].url
          this.callback(this.data.image)
        }.bind(this)
      })
    },
    deleteImage () {
      this.data.image = ''
      this.callback(this.data.image)
      this.newSettings = this.getSettings()
      this.updateKey = new Date().getTime()
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .uploadImg
    max-width: 100%
    overflow: hidden
    overflow-x: auto
</style>
